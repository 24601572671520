<template>
    <div class="container">
        <FilterDashboard @filter="onFilter" @filterStart="onFilterStart" @filterEnd="onFilterEnd"
            @filterStatus="onFilterStatus" @filterNivel="onFilterNivel" :isClosingDate="true" :showNivel="true" />

        <div style="text-align:right" class="chart">
            <div class="row">
                <div class="col-md-7">
                    <h5>Vacantes Fin vs Terna</h5>
                    <h6>Total de vacantes: {{ total }}</h6>
                    <h6>Promedio - Fecha Terna (días hábiles) : {{ promedioTerna }}</h6>
                    <h6>Promedio - Fecha Fin (días hábiles): {{ promedioFin }}</h6>
                </div>

            </div>

        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series">
            </apexchart>
        </div>

    </div>
</template>

<script>

import CompanyService from "../../services/company.service";
import VueApexCharts from 'vue3-apexcharts';
import FilterDashboard from "../filter/FilterDashboard.vue";

export default ({
    name: 'vacantes-fin-terna',
    components: { apexchart: VueApexCharts, FilterDashboard },
    data() {
        return {
            clearFilter: false,
            total: 0,
            promedioTerna: 0,
            promedioFin: 0,
            options: {
                chart: {
                    id: 'vuechart-fin-terna',
                    type: 'bar',
                    width: 700
                },
                xaxis: {
                    labels: {
                        rotate: -45
                    },
                    categories: [],
                    tickPlacement: 'on'
                },
                yaxis: {
                    title: {
                        text: 'Días Hábiles'
                    }
                },
                fill: {
                    opacity: 1,
                    //colors: ['#00BFFF', '#A9A9A9']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '',
                        distributed: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                colors: []
                            }],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                        dataLabels: {
                            position: 'top',
                            maxItems: 10,
                            total: {
                                enabled: true,
                                formatter: undefined,
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: '#373d3f',
                                    fontSize: '12px',
                                    fontFamily: undefined,
                                    fontWeight: 600
                                }
                            }
                        }
                    }
                }
            },
            series: [],
            user: JSON.parse(localStorage.getItem("user")),
            niveles: [],
            filtered: {
                filterStart: "",
                filterEnd: "",
                filterStatus: "",
                filterNivel: "",
                userId: ""
            }
        }
    },
    mounted() {
        this.filtered.userId = this.user._id;
        // this.cargaDatosByUser(this.user);
    },
    methods: {

        async cargaDatosByUser(user) {
            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series = [];

            this.getVacantesByFechaFinVsFechaFinAndUserId(user._id);

        },
        async cargaDatosByFilter() {
            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series = [];


            this.getVacantesByFechaFinVsFechaFinFilter();

        },

        async getVacantesByFechaFinVsFechaFinAndUserId(userId) {
            try {
                const response = await CompanyService.getVacantesByFechaFinVsFechaTernaAndUserId(userId);

                const { data } = response.data;

                this.promedioTerna = data[0].totalDaysTernaAvg;
                this.promedioFin = data[0].totalDaysClosingDateAvg;
                this.total = data[0].totalVacantes;


                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);

                });

                let infoTerna = {
                    name: "Fecha Terna",
                    data: []
                }
                let infoFin = {
                    name: "Fecha Fin",
                    data: []
                }
                data[0].vacantes.map((item) => {
                    infoTerna.data.push(item.totalDaysTerna);
                    infoFin.data.push(item.totalDaysClosingDate);
                });

                this.series.push(infoTerna);
                this.series.push(infoFin);
                //this.series.shift();

                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };

                //this.options.chart.width = countColumns > 10 ? countColumns * 6 : '';

                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },

        async getVacantesByFechaFinVsFechaFinFilter() {
            try {
                const response = await CompanyService.getVacantesByFechaFinVsFechaTernaFilter(this.filtered);

                const { data } = response.data;

                this.promedioTerna = data[0].totalDaysTernaAvg;
                this.promedioFin = data[0].totalDaysClosingDateAvg;
                this.total = data[0].totalVacantes;


                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);

                });

                let infoTerna = {
                    name: "Fecha Terna",
                    data: []
                }
                let infoFin = {
                    name: "Fecha Fin",
                    data: []
                }
                data[0].vacantes.map((item) => {
                    infoTerna.data.push(item.totalDaysTerna);
                    infoFin.data.push(item.totalDaysClosingDate);
                });

                this.series.push(infoTerna);
                this.series.push(infoFin);

                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };

                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },

        onFilter(filter) {
            this.filtered.filter = filter;
            this.cargaDatosByFilter();
        },
        onFilterStart(filter) {
            this.filtered.filterStart = filter;
        },
        onFilterEnd(filter) {
            this.filtered.filterEnd = filter;
            this.cargaDatosByFilter();
        },
        onFilterStatus(filter) {
            this.filtered.filterStatus = filter;
            this.cargaDatosByFilter();
        },
        onFilterNivel(filter) {
            this.filtered.filterNivel = filter;
            this.cargaDatosByFilter();
        },
        onClearFilter(filter) {
            if (filter) {
                this.cargaDatosByUser(this.user);
            }

        },
        changeChart(type) {
            const chartTypes = {
                fin: 'activeFin',
                admision: 'activeAdmision',
                terna: 'activeTerna'
            };

            this.activeFin = false;
            this.activeAdmision = false;
            this.activeTerna = false;

            if (chartTypes[type] !== undefined) {
                this[chartTypes[type]] = !this[chartTypes[type]];
            }

            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series[0] = { ...this.series[0], data: [] };

            if (this.filtered)
                this.cargaDatosByFilter();
            else
                this.cargaDatosByUser(this.user);

        },

    }
})
</script>

<style>
.chart {
    padding-top: 3%;
}
</style>
